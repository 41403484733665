.t-navbar{
    padding-top: 32px;
    position: absolute;
    z-index: 10;
    width: 100%;
}
.t-navbar > .container{
    box-sizing: border-box;
    max-width:1440px;
    position:relative;
    width: 100%;
    margin: auto;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

.t-navbar > .container > .nav-menu{
  display: flex;
  align-items: center;
}
.t-navbar > .container > .nav-menu.active{
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    height: 100vh;
    top: 0;
    background-color: rgba(18, 18, 18, 1);;
    padding: 60px 40px;
}
.t-navbar > .container > .nav-menu > div{
    padding-right: 24px;
}
.t-navbar > .container > .nav-menu > div::after{
    content: '';
    background: url('../assets//asset_homepage/carret.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 24px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 4px;
}
.t-navbar > .container > .nav-menu > div.active::after{
    transform: rotate(180deg);
}
.t-navbar > .container > .nav-menu > .nav-link-wrap{
  position: relative;
  margin: 0 20px;
}
.t-navbar > .container > .nav-menu > .nav-link-wrap > .nav-link-head,
.t-navbar > .container > .nav-menu > .nav-link-wrap{
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}
.t-navbar > .container > .nav-menu > .nav-link-wrap > .nav-link-body{
  display:none;
  position: absolute;
  top:100%;
  background-color: rgba(18, 18, 18, 1);
  padding: 20px;
  min-width: 100%;
  border-radius: 10px;
  z-index: 13;
}
.t-navbar > .container > .nav-menu > .nav-link-wrap > .nav-link-body > a{
  margin-bottom: 20px;
  font-weight: 400;
  display: block;
  font-size: 16px;
  line-height: 120%;
  min-width: 100%;
}
.t-cursor{
  cursor: pointer;
  user-select: none;
}
.t-navbar > .container > .nav-menu > .nav-link-wrap.special{
    border-radius: 40px;
    padding: 13px 39px;
    /* border: 1px solid white; */
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: white;
    background: linear-gradient(265.9deg, #006AB8 -6.49%, #0093FF 104.26%);
}
.t-nav-close,
.t-nav-open{
    width: 23px;
    height: 23px;
    display: none;
}
.t-nav-close{
    margin-bottom: 30px;
    align-self: flex-end;
}
.homepage-landing-sec1{
    padding-top: 341px;
    background: url('../assets/asset_homepage/home-gem.png'), url('../assets/asset_homepage/home-line.png');
    background-position: 12% 90%, 47% 30%;
    background-repeat: no-repeat;
    background-size: 100%, 150%;
    padding-bottom: 285px;
}

.home-main-box{
    width: 100%;
    position: relative;
    padding: 0 81px;
    padding-top: 74px;
    padding-bottom: 65px;
    margin-bottom: 73px;
    background: url("../assets/asset_homepage/border-neon.png");
    background-position: center;
    background-size: 102% 110%;
    background-repeat: no-repeat;
}
.home-main-box > img{
  position: absolute;
  top: -210px;
  max-width: 250px;
  width: 100%;
}
.home-main-box > p{
  position: relative;
  margin-bottom: 58px;
  text-shadow: 0px 0px 15px rgba(14,147,245,1);
}
.home-main-box > p::after{
  position: absolute;
  width: 100%;
  height: 100%;
  content: 'degem is a decentralized products and services platform that empowers the investor, simplifies the trading experience, and gives YOU the edge needed to be successful';
  top: 0;
  left: 0;
  color: #0E93F5;
  filter: blur(16px);
}
.home-main-box > .btn-wrap > a{
    background: linear-gradient(264.76deg, #00C9D4 0%, #0E93F5 88.97%);
    border-radius: 60px;
    padding: 16px 67px;
    margin: 0 11px;
}
.home-main-box > .btn-wrap > a > img{
  margin-right: 6px;
}


.t-navbar > .container > .nav-menu > .nav-link-wrap.special{
    background: #0E93F5;
}








@media all and (max-width: 972px){
    .t-navbar > .container > .nav-menu{
        display: none;
    }
    .t-nav-close,
    .t-nav-open{
        display: block;
    }
    .t-navbar > .container > .nav-menu.active{
        align-items: flex-start;
    }
    .t-navbar > .container > .nav-menu > .nav-link-wrap{
        margin-bottom: 15px;
    }
    .t-navbar > .container > .nav-menu > .nav-link-wrap > .nav-link-body{
        position: relative;
        top: 0;
        padding: 20px 0;
    }
    .btn-wrap {
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
    }
    .btn-tele {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }
    .home-main-box p {
        font-size: 14px !important;
        line-height: 21px !important;
    }
}
@media all and (max-width: 768px),
all and (orientation: landscape) and (max-width: 768px){
    .btn-wrap {
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
    }
    .btn-tele {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }
    .home-main-box p {
        font-size: 14px !important;
        line-height: 21px !important;
    }
    
}
@media all and (max-width: 568px){
    .btn-wrap {
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
    }
    .btn-tele {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }
    .home-main-box p {
        font-size: 14px !important;
        line-height: 21px !important;
    }
}

@media all and (max-width: 430px){
    .btn-wrap {
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
    }
    .btn-tele {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }
    .home-main-box p {
        font-size: 14px !important;
        line-height: 21px !important;
    }
}
@media all and (max-width: 320px){
    .btn-wrap {
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
    }
    .btn-tele {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }
    .home-main-box p {
        font-size: 14px !important;
        line-height: 21px !important;
    }
}
