*{
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    line-height: 14px;
    letter-spacing: 0.04em;
    color: white;
}
body{
    max-width:100vw;
    background: none;
    background-color: #000000;
    overflow: auto;
    margin: unset;
}
a{
    text-decoration: none;
    color:white;
}
button,
input,
select,
textarea{
    appearance: none;
    -webkit-appearance: none;
    border:unset;
    background-color: transparent;
    margin: unset;
    padding: unset;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.section{
    width:100%;
    max-width:100vw;
    position:relative;
}
.container{
    box-sizing: border-box;
    max-width:1440px;
    position:relative;
    width: 100%;
    margin: auto;
    padding: 0 40px
}
.cursor{
    cursor: pointer;
}
.block,
.m-hide{
    display: block;
}
.d-none,
.m-show {
    display: none;
}


.margin-btm-16{
    margin-bottom: 16px;
}
.margin-btm-18{
    margin-bottom: 18px;
}
.margin-btm-24{
    margin-bottom: 24px;
}
.margin-btm-32{
    margin-bottom: 32px;
}
.margin-btm-34{
    margin-bottom: 34px;
}
.margin-btm-35{
    margin-bottom: 35px;
}
.margin-btm-48{
    margin-bottom: 48px;
}
.margin-btm-51{
    margin-bottom: 51px;
}
.margin-btm-62{
    margin-bottom: 62px;
}
.margin-btm-80{
    margin-bottom: 80px;
}




.flex-r{
    display: flex;
    flex-direction: row;
}
.flex-r.v-center{
    align-items: center;
}
.flex-r.v-top{
    align-items: flex-start;
}
.flex-r.v-btm{
    align-items: flex-end;
}
.flex-r.h-center{
    justify-content: center;
}
.flex-r.h-left{
    justify-content: start;
}
.flex-r.h-right{
    justify-content: end;
}
.flex-r.just-space{
    justify-content: space-between;
}
.flex-c{
    display: flex;
    flex-direction: column;
}
.flex-c.h-center{
    align-items: center;
}
.flex-c.h-left{
    align-items: flex-start;
}
.flex-c.h-right{
    align-items: flex-end;
}
.flex-c.v-center{
    justify-content: center;
}
.flex-c.v-top{
    justify-content: start;
}
.flex-c.h-btm{
    justify-content: end;
}


.t-400{
    font-weight: 400;
}
.t-500{
    font-weight: 500;
}
.t-600{
    font-weight: 600;
}
.t-700{
    font-weight: 700;
}
.t-800{
    font-weight: 800;
}



.t-12-14{
    font-size: 12px;
    line-height: 14px;
}
.t-14-14{
    font-size: 14px;
    line-height: 14px;
}
.t-14-16{
    font-size: 14px;
    line-height: 16px;
}
.t-16-19{
    font-size: 16px;
    line-height: 120%;
}
.t-16-24{
    font-size: 16px;
    line-height: 24px;
}
.t-18-21{
    font-size: 18px;
    line-height: 120%;
}
.t-18-27{
    font-size: 18px;
    line-height: 27px;
}
.t-20-24{
    font-size: 20px;
    line-height: 120%;
}
.t-24-36{
    font-size: 24px;
    line-height: 36px;
}
.t-28-42{
    font-size: 28px;
    line-height: 42px;
}
.t-40-60{
    font-size: 40px;
    line-height: 60px;
}



.t-center{
    text-align: center;
}
.t-right{
    text-align: right;
}
.t-left{
    text-align: left;
}




.neon-box{
    border: 1px solid #E5C10E;
    box-shadow: 0px 0px 15px 3px rgba(229, 193, 14, 0.5);
    position: relative;
}
.neon-box::after{
    position: absolute;
    z-index: -1;
    content:'';
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 15px 3px rgba(229, 193, 14, 0.5);
}
.btn-1{
    background: #E5C10E;
    border-radius: 60px;
    padding: 14px 41px;
    color: black;
}
.navbar{
    margin-bottom: 65px;
    padding-top: 32px;
}
.navbar > .container > .logout-btn{
    border-radius: 60px;
    padding: 13px 39px;
    border: 1px solid white;
}
.navbar > .container > .logout-btn > img{
    width: 24px;
    height: 24px;
}
.navbar > .container > a > img{
    width: 177px;
}



.dapp-main-content{
    padding-bottom: 113px;
    background: none !important;
}
.dapp-main-content > .container > .left-block{
    flex:1;
}
.dapp-main-content > .container > .right-block{
    width:100%;
    max-width: 650px;
    margin-left: 71px;
}
.dapp-main-content > .container > .right-block > img{
    width: 174.02px;
    margin-bottom: 46px;
    padding-top: 17px;
}
.dapp-main-content > .container > .right-block > .address-block{
    padding: 25px;
}
.dapp-main-content > .container > .right-block > .address-block > div{
    flex:1;
}
.dapp-main-content > .container > .right-block > .address-block > div > span{
    color: rgba(255, 255, 255, 0.5);
}
.dapp-main-content > .container > .right-block > .address-block > div > div > img{
    margin-left: 12px;
}
.dapp-main-content > .container > .right-block > .address-block > button{
    margin-left: 39px;
}


.left-block > .left-itm{
    padding: 36px 47px;
    position: relative;
    margin-bottom: 18px;
}
.left-block > .left-itm > img{
    position: absolute;
    background-color: #E5C10E;
    border-radius: 50%;
    padding: 12px;
    width: 48px;
    height: 48px;
    z-index: 2;
    box-shadow: 0px 0px 15px 3px rgba(229, 193, 14, 0.5);
    left: -24px;
}
.left-block > .left-itm > span{
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 6px;
}
.left-block > .left-itm > div{
    position: relative;
}
.left-block > .left-itm > div > span{
    margin-left: -7px;
}
.left-block > .left-itm > div::before{
    content: '';
    position: absolute;
    z-index: -1;
    background-color: #E5C10E;
    filter: blur(30px);
    width: 130px;
    height: 10px;
    left: 0;
    top: 15px;
}


.dapp-main-content > .container > .right-block > .reward-block{
    padding: 40px 25px;
    margin-top: 20px;
    border-bottom:unset;
}
.dapp-main-content > .container > .right-block > .reward-block > img{
    width: 76px;
    height: 76px;
    object-fit: contain;
    box-shadow: 0px 0px 15px 3px rgba(229, 193, 14, 0.5);
    border-radius: 50%;
    padding:14px;
    background-color: rgba(229, 193, 14, 1);
    margin-right: 23px;
}
.dapp-main-content > .container > .right-block > .reward-block > div > div {
    margin-bottom: 23px;
}
.dapp-main-content > .container > .right-block > .reward-block > div > span {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: -5px;
}
.dapp-main-content > .container > .right-block > .reward-block > div > div > span{
    margin-left: -5px;
}


.dapp-main-content > .container > .right-block > .reward-pool-block{
    padding: 28px 25px;
}
.dapp-main-content > .container > .right-block > .reward-pool-block > span:first-child{
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 6px;
}

.dapp-main-content > .container > .right-block > .address-block > div > div > span{
    overflow: hidden;
    text-overflow: ellipsis;
}


.m-logo{
    margin: auto;
    margin-bottom: 24px;
}




@media all and (min-width: 1280px){
    .container{
        padding: 0 60px
    }
}
@media all and (min-width: 1440px){
    .container{
        padding: 0 100px
    }
}




@media all and (max-width: 1140px){
}
@media all and (max-width: 1080px){
    .dapp-main-content > .container{
        flex-direction: column;
    }
    .dapp-main-content > .container > .right-block{
        margin-left: unset;
        max-width: 100%;
    }
    .m-show{
        display: block !important;
    }
    .m-hide{
        display: none !important;
    }
    .navbar > .container > .logout-btn{
        padding: unset;
        border: unset;
    }
    .main-content > .container > .left-block{
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        padding-left: 34px;
        margin-bottom: 20px;
        width: 100vw;
        margin-left: -40px;
    }
    .left-block > .left-itm{
        display: inline-block;
        margin-right: 44px;
    }
}
@media all and (max-width: 994px){
    
}
@media all and (max-width: 972px){
    .container{
        padding: 0 20px
    }
    .dapp-main-content > .container > .left-block{
        margin-left: -20px;
    }
}
@media all and (max-width: 768px),
all and (orientation: landscape) and (max-width: 768px){

    .t-12-14{
        font-size: 11px;
        line-height: 13px;
    }
    .t-14-14{
        font-size: 12px;
        line-height: 14px;
    }
    .t-14-16{
        font-size: 12px;
        line-height: 14px;
    }
    .t-16-19{
        font-size: 12px;
        line-height: 14px;
    }
    .t-18-21{
        font-size: 16px;
        line-height: 18px;
    }
    .t-20-24{
        font-size: 18px;
        line-height: 21px;
    }

    .dapp-main-content > .container > .right-block > .address-block{
        flex-direction: column;
    }
    .dapp-main-content > .container > .right-block > .address-block > button{
        margin-left: unset;
        margin-top: 26px;
    }
}
@media all and (max-width: 568px){
    .dapp-main-content > .container > .right-block > .reward-block{
        flex-direction: column;
    }
    .dapp-main-content > .container > .right-block > .reward-block > img{
        margin-right: unset;
        margin-bottom: 7px;
    }
    .dapp-main-content > .container > .right-block > .reward-block > div > button{
        width: 100%;
    }
}

@media all and (max-width: 430px){
    
}
@media all and (max-width: 320px){
    
}