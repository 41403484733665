.t-footer{
    padding-bottom: 48px;
    position: relative;
    background: linear-gradient(0deg, #1A1A1A 35.63%, rgba(26, 26, 26, 0) 100%);
}
.t-footer > .container:first-child{
    padding-top: 64px;
}
.t-footer > .container > .foot-line{
    background: rgb(255,255,255);
    background: -moz-radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    width: 100%;
    position: absolute;
    top:0;
    left: 0;
    opacity: 0.4;
    height: 1px;
}
.t-footer > .container:last-child{
    margin-top: 105px;
    color: rgba(255, 255, 255, 0.5);
}
.t-footer > .container > .company-wrap{
    margin-right: 107px;
}
.t-footer > .container > .company-wrap > div{
    margin-top: 6px;
    margin-bottom: 16px;
}
.t-footer > .container > .company-wrap > button{
    padding: 14px 61px;
    /* background: linear-gradient(265.9deg, #006AB8 -6.49%, #0093FF 104.26%); */
    border-radius: 30px;
}
.t-footer > .container > .foot-menu-wrap{
    flex:1;
}
.t-footer > .container > .foot-menu-wrap > .foot-menu > .head{
    margin-bottom: 28px;
}
.t-footer > .container > .foot-menu-wrap > .foot-menu > a{
    margin-bottom: 12px;
}
.t-footer > .container > .foot-menu-wrap > .foot-menu > a > img{
    width: 24px;
    margin-right: 18px;
}
@media all and (max-width: 972px){
    .t-footer > .container:first-child{
        flex-direction: column;
        align-items: flex-start;
    }
    .t-footer > .container > .company-wrap{
        margin-right: unset;
        margin-bottom: 30px;
    }
    .t-footer > .container > .foot-menu-wrap{
        width: 100%;
    }
}
@media all and (max-width: 768px),
all and (orientation: landscape) and (max-width: 768px){
    .main-box > img{
        top: -105px;
        max-width: 200px;
    }            
}
@media all and (max-width: 568px){
    .t-footer > .container > .foot-menu-wrap{
        flex-direction: column;
    }
    .foot-menu-wrap > .foot-menu{
        margin-bottom: 20px;
    }
}

@media all and (max-width: 430px){
    .main-box > img{
        top: -70px;
        max-width: 150px;
    }   
}
@media all and (max-width: 320px){
    
}