

.t-navbar{
    margin-bottom: 65px;
    padding-top: 32px;
    position: absolute;
    z-index: 10;
    width: 100%;
}
.t-navbar > .container{
    box-sizing: border-box;
    max-width:1440px;
    position:relative;
    width: 100%;
    margin: auto;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

.t-navbar > .container > .nav-menu{
  display: flex;
  align-items: center;
}
.t-navbar > .container > .nav-menu.active{
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    height: 100vh;
    top: 0;
    background-color: rgba(18, 18, 18, 1);;
    padding: 60px 40px;
}
.t-navbar > .container > .nav-menu > div{
    padding-right: 24px;
}
.t-navbar > .container > .nav-menu > div::after{
    content: '';
    background: url('../../assets/asset_global/carret.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 24px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 4px;
}
.t-navbar > .container > .nav-menu > div.active::after{
    transform: rotate(180deg);
}
.t-navbar > .container > .nav-menu > .nav-link-wrap{
  position: relative;
  margin: 0 20px;
}
.t-navbar > .container > .nav-menu > .nav-link-wrap > .nav-link-head,
.t-navbar > .container > .nav-menu > .nav-link-wrap{
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}
.t-navbar > .container > .nav-menu > .nav-link-wrap > .nav-link-body{
  display:none;
  position: absolute;
  top:100%;
  background-color: rgba(18, 18, 18, 1);
  padding: 20px;
  min-width: 100%;
  border-radius: 10px;
  z-index: 13;
}
.t-navbar > .container > .nav-menu > .nav-link-wrap > .nav-link-body > a{
  margin-bottom: 20px;
  font-weight: 400;
  display: block;
  font-size: 16px;
  line-height: 120%;
  min-width: 100%;
}
.t-cursor{
  cursor: pointer;
  user-select: none;
}
.t-navbar > .container > .nav-menu > .nav-link-wrap.special{
    border-radius: 40px;
    padding: 13px 39px;
  
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: white;
}
.t-nav-close,
.t-nav-open{
    width: 23px;
    height: 23px;
    display: none;
}
.t-nav-close{
    margin-bottom: 30px;
    align-self: flex-end;
}


@media all and (max-width: 972px){
    .t-navbar > .container > .nav-menu{
        display: none;
    }
    .t-nav-close,
    .t-nav-open{
        display: block;
    }
    .t-navbar > .container > .nav-menu.active{
        align-items: flex-start;
    }
    .t-navbar > .container > .nav-menu > .nav-link-wrap{
        margin-bottom: 15px;
    }
    .t-navbar > .container > .nav-menu > .nav-link-wrap > .nav-link-body{
        position: relative;
        top: 0;
        padding: 20px 0;
    }
}
@media all and (max-width: 768px),
all and (orientation: landscape) and (max-width: 768px){

    
}
@media all and (max-width: 568px){
    
}

@media all and (max-width: 430px){
    
}
@media all and (max-width: 320px){
    
}