

li{
  list-style: none;
}


.logo > img.large {
  display: block;
  width: 177px;
  height: auto;
}


.t-sec1{
  background: url('../assets/asset_treasury/ornamen-bg-treasury.png');
  background-position:  47% 100%;
  background-repeat: no-repeat;
  background-size: 200%;
  padding-bottom: 400px;
  padding-top: 10px;
 
}
.tres-main-box{
  margin-top: 341px;
  width: 100%;
  position: relative;
  padding: 0 81px;
  padding-top: 74px;
  padding-bottom: 65px;
  margin-bottom: 657px;
  background: url("../assets/asset_treasury/line_treasury.png");
  background-position: center;
  background-size: 102% 110%;
  background-repeat: no-repeat;
}
.tres-main-box > img{
position: absolute;
top: -210px;
max-width: 300px;
width: 100%;
}
.tres-main-box > p{
position: relative;
margin-bottom: 58px;
}
.tres-main-box > a{
  background: #E5C10E;
  border-radius: 60px;
  padding: 16px 67px;
  margin: 0 11px;
}

.tres-margin-top {
  margin-top: -300px;
}

/* sec-hero */


.btn-hero{
  width: 230px;
  height: 55px;
}


/* sec-tier */
.bg-tier{
  left: -750px;
  top: 200px;
}

.t-tres-tittle{
  font-size: 55px;
  line-height: 71px;
}

.t-tres-p{
  font-size: 24px;
line-height: 36px;
color: #FFFFFF;
opacity: 0.7;
}


.tier, .tier-1, .tittle-tier{
  display: flex;
  position: relative;
}
    
.tier{
  align-items: center;
  padding-top: 200px;
}

.img-tier, .tittle-tier{
  width: 100%;
  justify-content: center;
}
.diagram{
  width: 70%;
}

.margin-btm-100{
 margin-bottom: 200px;

}

.diag-1{
  flex-direction: column;
  position: relative;
      max-width: 600px;
      width:100%;
      /* height: 389px; */
      /* border-top: 1px solid #E5C10E; */
/* border-right: 1px solid #E5C10E; */
/* border-bottom: 1px solid #E5C10E; */
box-sizing: border-box;
      
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 70px 40px;
      /* box-shadow: 0px 0px 15px 3px #E5C10E; */
}
.diag-1.right{
  background: url("../assets/asset_treasury/neon-right.png");
  background-position: center;
  background-size: 102% 110%;
  background-repeat: no-repeat;
}
.diag-1.left{
  background: url("../assets/asset_treasury/neon-left.png");
  background-position: center;
  background-size: 102% 110%;
  background-repeat: no-repeat;
}

.diag-1 > h1{
  margin-top: unset;
  margin-bottom: 28px;
}
.diag-1 > p{
  margin: unset;
}





.minus-margin{
  margin-top: -400px;
}



/* sec-footer */

.sec-footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 40px 0;
  margin-top: 140px;
}

.footer, .logo-ecosystem, .menu-right{
  display: flex;
}

.menus{
padding:0 20px 0 20px ;
}

.ul-link{
  
    display: flex;
    flex-direction: column;
    padding: 0;
}

.m-right{
  margin-right: 100px;
}

.m-center{
  margin: 0 200px 0 200px;
}

.copyright{
  margin-top: 100px;
  color: #FFFFFF;
  opacity: 0.5;
}

.btn-footer{
  Width : 200px;
Height : 45px;
}

.margin-btm-12{
  margin-bottom: 12px;
}

.t-footer-tittle{
  font-size: 22px;
line-height: 33px;
}

.logo-degem{
  margin-top: -15px;
}

.logo-ecosystem > img {
  width: 20%;
}

.logo-ecosystem {
  margin-bottom: 12px;
}

.logo-ecosystem > h1 {
  margin-left: 18px;
}

/* .t-navbar > .container > .nav-menu > .nav-link-wrap.special,
.t-footer > .container > .company-wrap > button{
    background: #E5C10E !important;
} */




/* respondsive  */
  
@media all and (min-width: 1280px){
  
}
@media all and (min-width: 1440px){

}




@media all and (max-width: 1140px){
}
@media all and (max-width: 1000px){
  
}
@media all and (max-width: 994px){

}
@media all and (max-width: 972px){
 
 
}
@media all and (max-width: 768px),
all and (orientation: landscape) and (max-width: 768px){
  .t-tres-tittle {
    font-size: 30px;
    line-height: 40px;
}
.tres-main-box{
  margin-top: 341px;

  padding: 0 81px;
  padding-top: 74px;
  padding-bottom: 65px;
  margin-bottom: 70px;

}




  .t-12-14{
      font-size: 11px;
      line-height: 13px;
  }
  .t-14-14{
      font-size: 12px;
      line-height: 14px;
  }
  .t-14-16{
      font-size: 12px;
      line-height: 14px;
  }
  .t-16-19{
      font-size: 12px;
      line-height: 14px;
  }
  .t-18-21{
      font-size: 16px;
      line-height: 18px;
  }
  .t-20-24{
      font-size: 18px;
      line-height: 21px;
  }
}
@media all and (max-width: 568px){
 
  .logo > img.big{
      display: block;
  }
  .logo > img.small{
      display: none;
  }
}

@media all and (max-width: 430px){
  .tier, .tier-1, .tittle-tier, .btn-wrap {
    display: flex;
    position: relative;
    flex-direction: column-reverse;
}

.mobile-ver{
  display: flex;
  flex-direction: column;
}

.tres-main-box {
  margin-top: 341px;
  padding: 0 20px;
  padding-top: 74px;
  padding-bottom: 65px;
  margin-bottom: 657px;
 
}

.tres-main-box > img {
  position: absolute;
  top: -280px;
  max-width: 300px;
  width: 100%;
}

.t-sec1 {
  
  background-position: 47% 70%;
  padding-bottom: 183px;
}

.margin-top{
  margin-top: -500px;
}

.diagram {
  width: 100%;
}

.diag-1.right{
  background: none;
  background-position: center;
  background-size: 102% 110%;
  background-repeat: no-repeat;
}
.diag-1.left{
  background: none;
  background-position: center;
  background-size: 102% 110%;
  background-repeat: no-repeat;
}

}


@media all and (max-width: 320px){
  
}