
li{
  list-style: none;
}


.btn-nav {

  background: linear-gradient(270deg, #e20deb 0%, #96029b 100%);
  border-radius: 60px;
}

.logo > img.large {
  display: block;
  width: 177px;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .Navbar__Items,
  .Navbar {
    flex-direction: column;
  }

  .Navbar__Items {
    display: none;
  }

  .Navbar__Items--right {
    margin-left: 0;
  }

  .Navbar__ToggleShow {
    display: flex;
  }

  .Navbar__Link-toggle {
    align-self: flex-end;
    display: initial;
    position: absolute;
    cursor: pointer;
  }
}
/* sec-hero from gecave  */
.sec-hero {
  padding-top: 20px;
}

.launch-main-box{
    width: 100%;
    position: relative;
    padding: 0 81px;
    padding-top: 74px;
    padding-bottom: 65px;
    margin-bottom: 73px;
    background: url("../assets/asset_launchpad/line launchpad.png");
    background-position: center;
    background-size: 102% 110%;
    background-repeat: no-repeat;
    margin-top: 300px;
}
.launch-main-box > img{
  position: absolute;
  top: -210px;
  max-width: 300px;
  width: 100%;
}
.launch-main-box > p{
  position: relative;
  margin-bottom: 58px;
}
.launch-main-box > a{
    background: #12CC3E;
    border-radius: 60px;
    padding: 16px 67px;
    margin: 0 11px;
}

  
  /* sec-hero */


  .btn-hero{
    width: 230px;
    height: 55px;
  }




/* sec-hero */

.bg-ornament{
  position: absolute;
    z-index: -9999;
    right: 0px;
    left: -450px;
    top: -350px;
    width: 200%;
}

.bg-ornament-btm{
  position: absolute;
  z-index: -9999;
  right: 0px;
  left: -800px;
    top: 100px;
  width: 200%;
}


.t-big-display{
  font-size: 72px;
line-height: 108px;
margin-top: -50px;
text-shadow: 1px 1px 24px #F30EF6;
}
.t-24-36 {
    font-size: 24px;
    line-height: 36px;

}
.hero-tittle{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  align-items: center;
}

.p-display{
  text-align: center;
    padding: 0 141px 0 141px;
    color: #D4D0D5;
    margin-top: -30px;
    /* margin-bottom: 80px; */
text-shadow: 0px 0px 44px #F30EF6;
}

.btn-hero-lanch {
  width: 230px;
  height: 55px;
}

.btn-nav-border {
  border-radius: 60px;
  border: 1px solid #F30EF6;
box-sizing: border-box;
color: #F30EF6;
}

.btn-wrap{
  display: flex;
  width: 100%;
  justify-content: center;
}

.m-btn-right{
  margin-right: 40px;
}

.symbol{
  /* margin-top: -50px; */
  width: 20%;
}
.position-pad{
  position: relative;
  z-index: 2;
  /* margin-bottom: -100px; */
}

.border-hero-pad{
  padding-top: 100px;
margin-top: -99px;
    width: 1160px;
    display: flex;
    flex-direction: column;
    align-items: center;
box-sizing: border-box;
position: relative;
/* filter: blur(25px); */
}
.position{
  top: 300px;
  position: absolute;
}



.bg-block-hero-pad {
display: flex;
width: 700px;
height: 108px;
justify-content: center;
background-color: #121212;
position: relative;
}

/* sec-tier */

.section-lanch{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  
}


.lanch-img{
  flex: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  display: flex;
  width: 50%;
}

.lanch-img > img {
  display: flex;
  position: absolute;
  width: 180%;
}

.margin-top{
  margin-top: 249px;
}

.bg-tier3{
  padding-top: 100px;
  background: url('../assets/asset_launchpad/ornament up.png');
  background-position:  47% 100%;
  background-repeat: no-repeat;
  background-size: 150%;
  padding-bottom: 266px;
}

.bg-tier2{
  padding-top: 100px;
  background: url('../assets/asset_launchpad/ornamen bottom.png');
  background-position:  47% -20%;
  background-repeat: no-repeat;
  background-size: 150%;
  padding-bottom: 266px;
}

.bg-tier1{
  padding-top: 100px;
  background: url('../assets/asset_launchpad/ornament up.png');
  background-position:  47% -20%;
  background-repeat: no-repeat;
  background-size: 150%;
  padding-bottom: 266px;
}

.lanch-text{
  flex-direction: column;
  position: relative;
      width: 50%;
      height: auto;
      background: rgba(131, 20, 161, 0.2);
      border: 1px solid #B21CDA;
      box-sizing: border-box;
      backdrop-filter: blur(10px);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 70px 70px;
      box-shadow: 0px 0px 15px 3px rgba(243, 14, 246, 0.5);
}




.t-tier-tittle{
  font-size: 55px;
  line-height: 82px;
}

.t-tier-p{
  font-size: 21px;
line-height: 31px;
}


.react{
  position: absolute;
}

.react-left-1{
  top: -40px;
  left: -70px;
}
.react-right-1{
  bottom: -35px;
  right: -35px;
}

.react-left-2{
  bottom: -38px;
  left: -40px;
}
.react-right-2{
  top: -40px;
    right: -46px;
}

.react-left-3{
  top: -40px;
  left: -45px;
}


/* sec-cta  */

.cta {
  display: flex;
  justify-content: center;
  padding: 40px 40px 90px 40px;
}

.cta-cont{
  width: auto;
  height: auto;
  padding: 85px 0 64px 0;
  background: rgba(131, 20, 161, 0.2);
  border: 1px solid #B21CDA;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-content: center;
}

.t-cta{
margin-bottom: 53px;

}

.btn-cta{
  width: 230px;
height: 55px;
}


/* .t-navbar > .container > .nav-menu > .nav-link-wrap.special,
.t-footer > .container > .company-wrap > button{
  background: linear-gradient(270deg, #e20deb 0%, #96029b 100%) !important;
} */


/* respondsive  */

@media all and (min-width: 1280px){
  
}
@media all and (min-width: 1440px){

}




@media all and (max-width: 1140px){
}
@media all and (max-width: 1000px){
  
}
@media all and (max-width: 994px){
 
}
@media all and (max-width: 972px){

  .t-big-display{
    font-size: 72px;
  line-height: 108px;
  margin-top: -50px;
  text-shadow: 1px 1px 24px #F30EF6;
  }
  .t-24-36 {
      font-size: 24px;
      line-height: 36px;
  
  }
  .t-tier-tittle{
    font-size: 35px;
    line-height: 40px;
  }
  
  .t-tier-p{
    font-size: 21px;
  line-height: 31px;
  }
 
}
@media all and (max-width: 768px),
all and (orientation: landscape) and (max-width: 768px){
  .launch-main-box {
    width: 100%;
    position: relative;
    padding: 0 20px;
    padding-top: 74px;
    padding-bottom: 65px;
   
}
  
  
  
  .btn-wrap {
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.btn-hero-lanch {
  max-width: 230px;
  height: 55px;
  width: 100%;
}

.lanch-text {
 
  width: 100%;
  padding: 0 10px 10px;
  margin-top: 200px;
}


.m-btn-right {
  margin-right: 0px;
  margin-bottom: 20px;
}
  /* sec-hero  */
  .p-display {
    text-align: center;
    padding: 0 1px 0 1px;
  

}
  .section, .section-lanch{
  flex-direction: column;
   
    
  }

  .mobile{
    flex-direction: column-reverse;
  }

/* sec-feature  */
.lanch-img{
  width: 65%;
}
.lanch-img > img {
  top: -500px;
}

.cta-cont {

  padding: 85px 10px 64px 10px;

}

.t-big-display{
  font-size: 72px;
line-height: 108px;
margin-top: -50px;

}
.t-24-36 {
  font-size: 16px;
  line-height: 30px;

}
.t-tier-tittle{
  font-size: 30px;
    line-height: 40px;
}

.t-tier-p{
  font-size: 12px;
  line-height: 24px;
}

  .t-12-14{
      font-size: 11px;
      line-height: 13px;
  }
  .t-14-14{
      font-size: 12px;
      line-height: 14px;
  }
  .t-14-16{
      font-size: 12px;
      line-height: 14px;
  }
  .t-16-19{
      font-size: 12px;
      line-height: 14px;
  }
  .t-18-21{
      font-size: 16px;
      line-height: 18px;
  }
  .t-20-24{
      font-size: 18px;
      line-height: 21px;
  }
}
@media all and (max-width: 568px){
 

}

@media all and (max-width: 430px){

  .bg-ornament {
    position: absolute;
    z-index: -9999;

    left: -451px;
  
    top: 0px;
    width: 800%;
}
.bg-tier3 {
  
  background-position: 47% 900%;
  background-repeat: no-repeat;
  background-size: 600%;
  padding-bottom: 266px;
}
.bg-tier2 {
  
  background-position: 47% -4000%;
  background-repeat: no-repeat;
  background-size: 600%;
  padding-bottom: 266px;
}
.bg-tier1 {
  
  background-position: 47% 190%;
  background-repeat: no-repeat;
  background-size: 600%;
  padding-bottom: 266px;
}
  .lanch-img{
    width: 90%;
  }
  .lanch-img > img {
    top: -300px;
  }


  .t-12-14{
      font-size: 11px;
      line-height: 13px;
  }
  .t-14-14{
      font-size: 12px;
      line-height: 14px;
  }
  .t-14-16{
      font-size: 12px;
      line-height: 14px;
  }
  .t-16-19{
      font-size: 12px;
      line-height: 14px;
  }
  .t-18-21{
      font-size: 16px;
      line-height: 18px;
  }
  .t-20-24{
      font-size: 18px;
      line-height: 21px;
  }
  .t-12-14{
      font-size: 11px;
      line-height: 13px;
  }
  .t-14-14{
      font-size: 12px;
      line-height: 14px;
  }
  .t-14-16{
      font-size: 12px;
      line-height: 14px;
  }
  .t-16-19{
      font-size: 12px;
      line-height: 14px;
  }
  .t-18-21{
      font-size: 16px;
      line-height: 18px;
  }
  .t-20-24{
      font-size: 18px;
      line-height: 21px;
  }
}
@media all and (max-width: 320px){
  
}