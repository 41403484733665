
.nav-menu > div,
.nav-menu > .mainbox-btn{
    margin-left: 35px;
}
.nav-menu > div{
    position: relative;
}
.nav-menu > div > div:last-child{
    display: none;
    position: absolute;
    top: 100%;
}
.nav-menu > div > div:last-child.active{
    display: flex;
}

.ex-landing-sec1{
    padding-top: 341px;
    background: url('../assets/asset_exchange_landing/gem.png'), url('../assets/asset_exchange_landing/line.png');
    background-position: 12% 80%, bottom;
    background-repeat: no-repeat;
    background-size: 53%, 120%;
}

.ex-main-box{
    width: 100%;
    position: relative;
    padding: 0 81px;
    padding-top: 74px;
    margin: unset;
    padding-bottom: 65px;
    margin-bottom: 73px;
    background: url("../assets/asset_exchange_landing/border-neon.png");
    background-position: center;
    background-size: 102% 110%;
    background-repeat: no-repeat;
}
.ex-main-box > img{
    position: absolute;
    top: -210px;
    max-width: 379px;
    width: 100%;
}
.ex-main-box > .mainbox-btn{
    background: linear-gradient(265.9deg, #006AB8 -6.49%, #0093FF 104.26%);
    border-radius: 60px;
    padding: 16px 67px;
}

.feature-box{
    padding: 52px 44px;
    max-width: 569px;
    width: 100%;
    background: rgba(14, 147, 245, 0.1);
    border: 1px solid #0E93F5;
    backdrop-filter: blur(10px);
    margin-bottom: 307px;
    box-shadow: 0px 0px 15px 0px rgba(14, 147, 245, 1), inset 0px 0px 15px 0px rgba(14, 147, 245, 1);
}
.feature-box > h2{
    margin: unset;
    margin-bottom: 27px;
}
.feature-box > div{
    background: url('../assets/asset_exchange_landing/feature-list.svg');
    background-position: left;
    background-repeat: no-repeat;
    background-size: 22px 22px;
    padding-left: 48px;
}




/* .t-navbar > .container > .nav-menu > .nav-link-wrap.special,
.t-footer > .container > .company-wrap > button{
    background: #0E93F5 !important;
} */
