*{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}
body{
    width:100vw;
    background-color: #000000;
    overflow: auto;
    margin: unset;
}
html {
    font-size: 62.5%;
    font-family: 'Poppins', sans-serif;
}
li {
    list-style: none;
}
a {
    text-decoration: none;
}
main {
    margin-top: 70px;
}

.container {
    padding-left: 122px;
    padding-right: 122px;
    margin: 0 auto;
    max-width: 1440px;
}
.cursor {
    cursor: pointer;
}
.d-none{
    display: none;
}
.d-block {
    display: block;
}
.d-flex {
    display: flex;
}
.t-white {
    color: #fff;
}
.t-green {
    color: #4CEC72;
    opacity: 1;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}
.t-500 {
    font-weight: 500;
}
.t-600 {
    font-weight: 600;
}
.t-700 {
    font-weight: 700;
}
.t-16-24 {
    font-size: 16px;
    line-height: 24px;
}
.t-12-18 {
    font-size: 12px;
    line-height: 18px;
}
.t-36-54 {
    font-size: 36px;
    line-height: 54px;
}
.t-14-21 {
    font-size: 14px;
    line-height: 21px;
}
.t-grey {
    color: rgba(255, 255, 255, 0.6);
}
.jcc {
    justify-content: center;
}
.aic {
    align-items: center;
}
.fdc {
    flex-direction: column;
}
.fdr {
    flex-direction: row;
}

.btn-green {
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 26px;
    padding-right: 26px;
    background-color: rgba(18, 204, 62, 0.21);
    border-radius: 60px;
    border: none;
}
/* navbar */
.header{
    /* border-bottom: 1px solid #E2E8F0; */
}
.nav-link {
    font-size: 18px;
    line-height: 27px;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
}
.hamburger {
    display: none;
}
.btn-connect {
    margin-left: 22px;
}
.btn-logout {
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 39px;
    padding-left: 39px;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 60px;
    margin-left: 12px;
}
.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
}
.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-logo {
    display: flex;
    justify-content: start;
    align-items: flex-start;
}

/* sidebar */
.c-home-sec-1 {
    display: flex;
    justify-content:end;
}
.home-sec-1 {
    /* margin-left: 20%; */
    /* background-color: rgb(0, 0, 0); */
    /* position: absolute; */
    /* margin-top: 160px; */
    /* top: 0; */
    /* width: 100%; */
    /* width: 1440px; */
}
aside {
    max-width: 1440px;
    margin: 0 auto;
}
.sidebar-wrapper {
    display: flex;
    flex-direction: column ;
    justify-content: center;
    max-width: 19%;
    position: absolute;
    top: 0px;
    left: 122px;
}
.side-wrapper {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 28px;
    padding-right: 28px;
    /* background-color: rgb(0, 0, 0); */
    border-radius: 30px;
    display: flex;
    justify-content: start;
    text-align: left;
    align-items: center;
    z-index: 99;
}
.side-wrapper:hover {
    background-image: linear-gradient(to right, rgba(18, 204, 62, 0), rgba(18, 204, 62, 0.3));
    cursor: pointer;
    color: #12CC3E;
    z-index: 99;
    width: 100%;
}
.side-active {
    background-image: linear-gradient(to right, rgba(18, 204, 62, 0), rgba(18, 204, 62, 0.3));
    cursor: pointer;
    color: #12CC3E;
    width: 100%;
}
.logo-green {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 223px;
}
.sidebar-wrapper img{
    margin-bottom: 40px;
    display: flex;
    justify-content: center !important;
    align-items: center;
}
/* main-content */
.glow-wrapper {
    overflow: auto;
    width: 75%;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 41px;
    padding-bottom:28px ;
    background-color: transparent;
    height: 100%;
    border: 1px solid rgba(18, 204, 62, 1);
    /* box-shadow: outer 0 0 10px rgba(18, 204, 62, 1); */
    box-shadow:inset 0 0 10px rgba(18, 204, 62, 1);
}
.main-content-title {
    display: flex;
    justify-content: space-around;
    align-items: start;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
.txt-wrapper-1 {
    width: 33%;
    text-align: left;
}
.txt-wrapper-2 {
    width: 35%;
    text-align: left;
}
.txt-wrapper-3 {
    width: 18%;
    text-align: left;
}
.txt-wrapper-4 {
    width: 24%;
    text-align: left;
}
.main-content-wrapper {
    display: flex;
    flex-direction: row;
}
.main-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.search-form-gemvault {
    position: relative;
    padding-left: 17px;
    width: 42%;
    height: 45px;
    border-radius: 9px;
    margin: 0 auto;
    background: rgba(33, 33, 33, 1);
    display: flex;
    align-items: center;
    justify-content: start;
    border: 1px solid rgba(255, 255, 255, 0.15);
    margin-left: 83px;
    margin-right: 12px;
}
.search-input {
    font-size: 14px;
    background: none;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.6);
    width: 90%;
    height: 20px;
    border: none;
    appearance: none;
    outline: none;
    display: flex;
    align-items: center;
}
.search-button {
    height: 24px;
    width: 24px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    outline: none!important;
    cursor: pointer;
    margin-right: 13px;
}
.main-content-body {
    margin-top: 38px;
}
.accordion {
    background-color: rgba(0, 0, 0, 0.07);
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
  }
  .ic-acc-wrapper{
      display: flex;
      align-items: center;
  }
  .ic-acc-wrapper span {
      margin-left: 14px;
  }
  .ic-front {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 217, 28, 1);
      border: 2px solid #000;
      border-style: outset;
  }
  .ic-behind {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -10px;
      background-color: rgba(14, 147, 245, 1);
      border: 2px solid #000;
      border-style: outset;
  }
  .ic-front, .ic-behind {
      width: 32px;
      height: 32px;
      border-radius: 50%;
  }
  .tvl-value-1 {
      margin-bottom: 6px;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .show, .accordion:hover {
    background-color: rgba(255, 255, 255, 0.07);
  }
  .show {
      background-color: rgba(255, 255, 255, 0.07);
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .accordion.show{
    background-color: rgba(255, 255, 255, 0.07);
  }
  .accordion:hover{
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }
  hr{
      border-color: rgba(255, 255, 255, 0.15);
  }
  /* Style the accordion panel. Note: hidden by default */
  .panel {
    padding: 0 18px;
    background-color: rgba(255, 255, 255, 0.07);
    display: none;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    padding-right: 16px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }
  .panel.show {
      display: block;
  }
  .accordion:after {
    content: ''; /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
    background-image: url(../assets/assets-gemvault/ic-dropdown.svg);
    width: 24px;
    height: 24px;
  }
  .panel-btn-wrapper {
      display: flex;
      justify-content: end;
      padding-top: 21px;
      padding-bottom: 20px;
  }
  .btn-solid-green {
      padding-top: 11px;
      padding-bottom: 11px;
      padding-left: 41px;
      padding-right: 41px;
      background-color: rgba(18, 204, 62, 1);
      border: none;
      border-radius: 60px;
  }
  
  /* .active:after {
    content: ""; 
    background-image: url(../assets-gemvault/ic-dropdown.svg);
    width: 24px;
    height: 24px;
  } */
  .tvl-value {
      display: flex;
      flex-direction: column;
  }
    .apy-value{
        display: flex;
        align-items: center;
    }
    .apy-value span {
        margin-right: 3px;
    }
    .btn-green-panel {
        padding-top: 11px;
        padding-bottom: 11px;
        padding-left: 45px;
        padding-right: 45px;
        background-color: rgba(18, 204, 62, 0.21);
        border: none;
        border-radius: 60px;
        margin-left: 10px;
    }
    .btn-transparent-panel {
        background-color: transparent;
        padding-top: 11px;
        padding-bottom: 11px;
        padding-left: 36px;
        padding-right: 36px;
        border: 2px solid rgba(18, 204, 62, 1);
        border-radius: 60px;
        margin-left: 10px;

    }
    .nav-menu.show {
        display: flex;
        right: 0px;
    }
@media only screen and (max-width: 768px) {
    .nav-menu {
        position: fixed;
        right: -200%;
        top: 0;
        /* margin-right: -30%; */
        display: none;
        /* margin-top: 100px; */
        padding-top: 100px;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        background-color: rgb(0, 0, 0);
        width: 70%;
        height: 100vh;
        border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        z-index: 99;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
    }
    .nav-menu .nav-item .nav-link {
        font-size: 48px;
        line-height: 72px;
        font-weight: 700;
    }
    .btn-connect {
        margin-left: 0;
    }
    .btn-logout {
        margin-left: 0;
    }
    .nav-menu.active {
        right: 0;
    }

    .nav-item {
        margin: 2.5rem 0;
    }
    .main-content-title, .tvl-value, .daily-value-txt , .apy-value{
        display: none;
    }
    .title-coin {
        display: block;
    }
    .panel-btn-wrapper {
        flex-direction: column;
    }
    .btn-solid-green {
        margin-bottom: 12px;
    }
    .btn-green-panel {
        margin-bottom: 12px;
    }
    .btn-solid-green, .btn-green-panel, .btn-transparent-panel {
        margin-right: 0;
        margin-left: 0;
    }
    .hamburger {
        display: block;
        cursor: pointer;
        z-index: 99;
    }
    .hamburger.show .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.show .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.show .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
    .panel.show {
        display: block;
    }
    .panel.show .tvl-mobile-wrapper {
        display: flex;
    }
    .panel.show .daily-apy-wrapper{
        display: flex;
    }
    .tvl-mobile-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .tvl-title {
        margin-top: 4px;
        margin-bottom: 6px;
    }
    .daily-apy-wrapper {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        margin-bottom: 40px;
    }
    .daily-mobile-wrapper {
        text-align: left;
        display: flex;
        flex-direction: column;
    }
    .apy-mobile-wrapper {
        margin-left: 45px;
    }
    .apy-ic-mobile {
        display: flex;
        flex-direction: row;
    }
    .apy-ic-mobile span {
        margin-right: 6px;
    }

}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    aside {
        display: none;
    }
    .navbar {
        display: flex;
        justify-content: space-between;
    }
    .glow-wrapper{
        width: 100%;
        max-width: 100%;
        overflow: auto;
        margin-top: 30px;
    }
    .container {
        padding-left: 38px;
        padding-right: 38px;
    }
    .main-content-header {
        flex-direction: column;
    }
    .search-form-gemvault {
        width: 100%;
        margin: 12px 12px 12px 12px !important;
    }
    .logo-green-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -30px;
        right: 0px;
        /* margin: 0 auto; */
        width: 100%;
    }
    .logo-green-wrapper img {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-button {
        display: none;
    }
    .tvl-mobile-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .tvl-title {
        margin-top: 4px;
        margin-bottom: 6px;
    }
    .daily-apy-wrapper {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        margin-bottom: 40px;
    }
    .daily-mobile-wrapper {
        text-align: left;
        display: flex;
        flex-direction: column;
    }
    .apy-mobile-wrapper {
        margin-left: 45px;
        
    }
    .apy-ic-mobile {
        display: flex;
        flex-direction: row;
    }
    .apy-ic-mobile span {
        margin-right: 6px;
    }
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    aside {
        /* display: none; */
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 767px) and (max-width:992px) {
    .main-content-header {
        display: flex;
        flex-direction: row !important;
    }
    .glow-wrapper {
        width: 70%;
    }
    .tvl-mobile-wrapper {
        display: flex;
    }
    .daily-apy-wrapper {
        display:flex ;
    }
    .panel-btn-wrapper {
        flex-direction: column;
    }
     .main-content-title, .tvl-value, .daily-value-txt , .apy-value{
        display: none;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width:1024px) {
    .main-content-title {
        display: none;
    }
    .main-content-header {
        flex-direction: column;
    }
    .search-form {
        width: 100%;
        margin: 0 12px;
        margin-top: 13;
        margin-bottom: 12px;
    }
    .glow-wrapper {
        width: 70%;
    }
    .tvl-mobile-wrapper {
        display: flex;
        flex-direction: column;
    }
    .daily-apy-wrapper {
        display:flex ;
        flex-direction: row;
        margin-top: 24px;
        margin-bottom: 40px;
    }
    .daily-mobile-wrapper {
        display: flex;
        flex-direction: column;
    }
    .apy-mobile-wrapper {
        flex-direction: column;
        margin-left: 45px;
    }
    .apy-ic-mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .panel-btn-wrapper {
        flex-direction: column;
    }
     .main-content-title, .tvl-value, .daily-value-txt , .apy-value{
        display: none;
    }
    .btn-solid-green {
        margin-bottom: 12px;
    }
    .btn-green-panel {
        margin-bottom: 12px;
    }
    .btn-solid-green, .btn-green-panel, .btn-transparent-panel {
        margin-right: 0;
        margin-left: 0;
    }
    
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}
