
  
  li{
    list-style: none;
  }
  
  /* navbar */

  /* .btn-nav-glob {
    background: #12CC3E;
    
  } */

  /* .t-navbar > .container > .nav-menu > .nav-link-wrap.special {
    background: #12CC3E;
  } */
  

/* from plain */

.gemcave-landing-sec1{
  padding-top: 341px;
  background: url('../assets/asset_gemcave/ornamen-bg-gemcave.png');
  background-position:  47% 100%;
  background-repeat: no-repeat;
  background-size: 150%;
  padding-bottom: 648px;
}

.gemcave-main-box{
    width: 100%;
    position: relative;
    padding: 0 81px;
    padding-top: 74px;
    padding-bottom: 65px;
    margin-bottom: 73px;
    background: url("../assets/asset_gemcave/green-neon.png");
    background-position: center;
    background-size: 102% 110%;
    background-repeat: no-repeat;
}
.gemcave-main-box > img{
  position: absolute;
  top: -210px;
  max-width: 300px;
  width: 100%;
}
.gemcave-main-box > p{
  position: relative;
  margin-bottom: 58px;
}
.gemcave-main-box > a{
    background: #12CC3E;
    border-radius: 60px;
    padding: 16px 67px;
    margin: 0 11px;
}

/* .t-navbar > .container > .nav-menu > .nav-link-wrap.special,
.t-footer > .container > .company-wrap > button{
  background: #12CC3E !important;
} */

  /* respondsive  */
  
  @media all and (min-width: 1280px){
   
  }
  @media all and (min-width: 1440px){
  
  }
  
  
  
  
  @media all and (max-width: 1140px){
  }
  @media all and (max-width: 1000px){
    
  }
  @media all and (max-width: 994px){
  
  }
  @media all and (max-width: 972px){
  
   
  }
  @media all and (max-width: 768px),
  all and (orientation: landscape) and (max-width: 768px){
   
  
  
  
    .t-12-14{
        font-size: 11px;
        line-height: 13px;
    }
    .t-14-14{
        font-size: 12px;
        line-height: 14px;
    }
    .t-14-16{
        font-size: 12px;
        line-height: 14px;
    }
    .t-16-19{
        font-size: 12px;
        line-height: 14px;
    }
    .t-18-21{
        font-size: 16px;
        line-height: 18px;
    }
    .t-20-24{
        font-size: 18px;
        line-height: 21px;
    }
  }
  @media all and (max-width: 568px){
   
    .logo > img.big{
        display: block;
    }
    .logo > img.small{
        display: none;
    }
  }
  
  @media all and (max-width: 430px){
    .t-18-21{
      font-size: 12px;
      line-height: 18px;
  }

  .gemcave-main-box > img {

    width: 50%;
}
.landing-sec1 {

  padding-top: 200px;
    padding-bottom: 200px;
}
.gemcave-main-box {
 
  padding: 0 40px;
  padding-top: 74px;
  padding-bottom: 65px;
 
}

.gemcave-landing-sec1 {
  background-position: 47% 70%;
  background-repeat: no-repeat;
  background-size: 150%;
  padding-bottom: 648px;
}
.gemcave-main-box {

  padding: 0 20px;
  padding-top: 74px;
  padding-bottom: 65px;
  margin-bottom: 73px;

}
.gemcave-main-box > img {

  top: -250px;
 
}
.gemcave-main-box > a {

  padding: 16px 60px;
 
}

.gemcave-main-box > img {
  position: absolute;
  top: -110px;
  max-width: 300px;
}

  }
  @media all and (max-width: 375px){
    .gemcave-main-box {
      padding: 0 10px;
      padding-top: 74px;
      padding-bottom: 65px;
  }
  .gemcave-main-box > img {
    top: -90px;
}
  }