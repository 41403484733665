* {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    line-height: 14px;
    letter-spacing: 0.04em;
    color: white;
  }
  
  body{
    width:100%;
    background: none;
    background-color :#121212;
    overflow-y: auto;
    overflow-x: hidden;
    margin: unset;
  }
  
  html{
    overflow-x: hidden;
  }
  
a{
    text-decoration: none;
    color:white;
}
button,
input,
select,
textarea{
    appearance: none;
    -webkit-appearance: none;
    border:unset;
    background-color: transparent;
    margin: unset;
    padding: unset;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.section{
    width:100%;
    max-width:100vw;
    position:relative;
}
.container{
    box-sizing: border-box;
    max-width:1440px;
    position:relative;
    width: 100%;
    margin: auto;
    padding: 0 40px
}
.cursor{
    cursor: pointer;
}
.block,
.m-hide{
    display: block;
}
.d-none,
.m-show {
    display: none;
}


.margin-btm-16{
    margin-bottom: 16px;
}
.margin-btm-18{
    margin-bottom: 18px;
}
.margin-btm-24{
    margin-bottom: 24px;
}
.margin-btm-32{
    margin-bottom: 32px;
}
.margin-btm-34{
    margin-bottom: 34px;
}
.margin-btm-35{
    margin-bottom: 35px;
}
.margin-btm-48{
    margin-bottom: 48px;
}
.margin-btm-51{
    margin-bottom: 51px;
}
.margin-btm-62{
    margin-bottom: 62px;
}
.margin-btm-80{
    margin-bottom: 80px;
}




.flex-r{
    display: flex;
    flex-direction: row;
}
.flex-r.v-center{
    align-items: center;
}
.flex-r.v-top{
    align-items: flex-start;
}
.flex-r.v-btm{
    align-items: flex-end;
}
.flex-r.h-center{
    justify-content: center;
}
.flex-r.h-left{
    justify-content: start;
}
.flex-r.h-right{
    justify-content: end;
}
.flex-r.just-space{
    justify-content: space-between;
}
.flex-c{
    display: flex;
    flex-direction: column;
}
.flex-c.h-center{
    align-items: center;
}
.flex-c.h-left{
    align-items: flex-start;
}
.flex-c.h-right{
    align-items: flex-end;
}
.flex-c.v-center{
    justify-content: center;
}
.flex-c.v-top{
    justify-content: start;
}
.flex-c.h-btm{
    justify-content: end;
}


.t-400{
    font-weight: 400;
}
.t-500{
    font-weight: 500;
}
.t-600{
    font-weight: 600;
}
.t-700{
    font-weight: 700;
}
.t-800{
    font-weight: 800;
}



.t-12-14{
    font-size: 12px;
    line-height: 14px;
}
.t-14-14{
    font-size: 14px;
    line-height: 14px;
}
.t-14-16{
    font-size: 14px;
    line-height: 16px;
}
.t-14-21{
    font-size: 14px;
    line-height: 21px;
}
.t-16-19{
    font-size: 16px;
    line-height: 120%;
}
.t-16-24{
    font-size: 16px;
    line-height: 24px;
}
.t-18-21{
    font-size: 18px;
    line-height: 120%;
}
.t-18-27{
    font-size: 18px;
    line-height: 27px;
}
.t-18-37{
    font-size: 18px;
    line-height: 37px;
}
.t-20-24{
    font-size: 20px;
    line-height: 120%;
}
.t-22-33{
    font-size: 22px;
    line-height: 33px;
}
.t-24-36{
    font-size: 24px;
    line-height: 36px;
}
.t-28-42{
    font-size: 28px;
    line-height: 42px;
}
.t-36-54{
    font-size: 36px;
    line-height: 54px;
}
.t-40-60{
    font-size: 40px;
    line-height: 60px;
}



.t-center{
    text-align: center;
}
.t-right{
    text-align: right;
}
.t-left{
    text-align: left;
}


